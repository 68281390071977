var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-12" }, [
    _c("label", { attrs: { for: "" } }, [_vm._v("Fecha")]),
    _vm._v(" "),
    _c("input", { attrs: { type: "date" }, domProps: { value: _vm.data } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }