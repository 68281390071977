import InputDate from '../components/inputdate.vue';
import Vue from 'vue/dist/vue.js';

new Vue({
    el: '#content',
    data: {
        title: "Hola, soy un app de Vue",
        text: "Estoy probando cositas"
    },
    components: {
        'calendar': InputDate
    },
    methods: {
        say() {
            console.log("hola");
        }
    }
});

import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import { Intersection } from '@splidejs/splide-extension-intersection';

const splide = new Splide('.splide', {
    direction: 'ttb',
    height: '45rem',
    type: 'loop',
    focus: 'center',
    perPage: 1,
    arrows: false,
    type: 'loop',
    autoplay: 'play',
    pauseOnHover: false,
    interval: 5000,
    classes: {
        pagination: 'splide__pagination splide_pagination--ttb-custom',
        page: 'splide__pagination__page bg-black'
    }
});

splide.mount();