<template>
    <div class="col-12">
      <label for="">Fecha</label>
      <input type="date" :value="data" />
    </div>
  </template>
  
  <script type="text/javascript">
  export default{
      data(){
          return{
              data:"2023-05-27"
          }
      }
  }
  </script>
  <style type="text/css"></style>